<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-0">
        {{ questionText }}
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UPEISafety5',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    questionNumber() {
      return this.taskState.getValueBySymbol('questionNumber').content;
    },
    questionText() {
      if (this.questionNumber.value === 1) {
        return 'What should you do if a reagent gets on your skin or in your eyes?';
      } else if (this.questionNumber.value === 2) {
        return 'Does how you dress when you go to lab help with keeping you safe?';
      } else if (this.questionNumber.value === 3) {
        return 'Following PEI OHS, are contact lenses allowed to be worn in laboratories?';
      } else if (this.questionNumber.value === 4) {
        return 'What is the proper procedure for moving hot glassware?';
      } else {
        return 'Which of the following statements are incorrect?';
      }
    },
    options1() {
      if (this.questionNumber.value === 1) {
        return [
          {
            text: 'Take care of the experiment first and then get help',
            value: '1',
          },
          {
            text: 'Rinse it with water for 15 minutes, disrobing if necessary',
            value: '2',
          },
          {
            text: 'Apply a neutralizing agent',
            value: '3',
          },
          {
            text: 'Wipe it off with a paper towel or blink rapidly',
            value: '4',
          },
        ];
      } else if (this.questionNumber.value === 2) {
        return [
          {text: 'Yes', value: '1'},
          {text: 'No', value: '2'},
          {text: 'Only in the summer', value: '3'},
        ];
      } else if (this.questionNumber.value === 3) {
        return [
          {text: 'Yes', value: '1'},
          {text: 'No', value: '2'},
          {text: 'Sometimes', value: '3'},
          {text: 'Only for people with blue eyes', value: '4'},
        ];
      } else if (this.questionNumber.value === 4) {
        return [
          {text: 'Use your bare hands', value: '1'},
          {text: 'Use tongs or gloves', value: '2'},
          {text: 'Get someone else to do it for you', value: '3'},
          {text: 'Wait for it to cool and then move it', value: '4'},
        ];
      } else {
        return [
          {
            text: 'Do not smoke in the K.C. Irving Chemistry Centre',
            value: '1',
          },
          {
            text: 'Do not store or consume food or drink in the laboratory',
            value: '2',
          },
          {
            text: 'Do not keep your workplace orderly while working',
            value: '3',
          },
          {
            text: 'Never work alone',
            value: '4',
          },
        ];
      }
    },
  },
};
</script>
